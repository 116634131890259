import React from 'react'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import {Button, Dialog, Text} from 'wix-ui-tpa'

import {ReserveReservationApplicationErrorCode} from '../../actions/reserveReservation'

import {classes, st} from './styles.st.css'
import {useHooks} from './useHooks'

interface Texts {
  title: string
  text: string
  ctaButton: string
}

const DEFAULT_TEXTS: Texts = {
  title: 'uou-reservations.reservation-details.error-modal.title',
  text: 'uou-reservations.reservation-details.error-modal.text',
  ctaButton: 'uou-reservations.reservation-details.error-modal.cta',
}

const APPLICATION_ERROR_CODE_TO_TEXTS: Record<ReserveReservationApplicationErrorCode, Texts> = {
  [ReserveReservationApplicationErrorCode.HELD_EXPIRED]: {
    title: 'uou-reservations.reservation-details.held-modal.title',
    text: 'uou-reservations.reservation-details.held-modal.text',
    ctaButton: 'uou-reservations.reservation-details.held-modal.cta',
  },
}

export const ErrorModal: React.FC = () => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()
  const {isModalOpen, onClose, onCtaButtonClick, apiRequestErrorDetails} = useHooks()

  const {title, text, ctaButton} = apiRequestErrorDetails?.applicationCode
    ? APPLICATION_ERROR_CODE_TO_TEXTS[apiRequestErrorDetails.applicationCode] ?? DEFAULT_TEXTS
    : DEFAULT_TEXTS

  return (
    <Dialog className={st(classes.root, {mobile: isMobile})} isOpen={isModalOpen} onClose={onClose}>
      <div className={classes.content}>
        <Text tagName="H3" className={classes.title}>
          {t(title)}
        </Text>
        <Text className={classes.text}>{t(text)}</Text>
        <Button onClick={onCtaButtonClick} className={classes.ctaButton}>
          {t(ctaButton)}
        </Button>
      </div>
    </Dialog>
  )
}
