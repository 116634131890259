import {StylesParamsType} from '../../../types/createStylesParams'
import {
  mobileDesignStylesParams,
  MobileDesignStylesParams,
} from '../../../components-shared/Settings/MobileDesign/stylesParams'
import {mobileLayoutStylesParams} from '../../../components-shared/Settings/MobileLayout/stylesParams'

export type MobileStylesParams = MobileDesignStylesParams

export const mobileStyleParams: StylesParamsType<MobileStylesParams> = {
  ...mobileDesignStylesParams,
  ...mobileLayoutStylesParams,
}
