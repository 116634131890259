import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import {Status} from '@wix/ambassador-table-reservations-v1-reservation/types'
import format from 'date-fns/format'
import React from 'react'
import {Text} from 'wix-ui-tpa'

import {classes, st} from './Counter.st.css'
import {useHooks} from './useHooks'

export const CountdownTimer: React.FC = () => {
  const {leftSec, reservation} = useHooks()
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()

  const formattedTime = format(leftSec * 1000, 'mm:ss')

  return (
    <section
      id="tr-timer"
      aria-flowto="tr-details"
      className={st(classes.root, {
        mobile: isMobile,
      })}
    >
      <Text className={classes.text} role={leftSec === 0 ? 'alert' : undefined} tagName="p">
        {leftSec > 0 && reservation?.status === Status.HELD
          ? t('uou-reservations.reservation-details.timer.held-for', {
              time: formattedTime,
            })
          : t('uou-reservations.reservation-details.timer.held-for-expired')}
      </Text>
    </section>
  )
}
