import React, {FC} from 'react'
import {Text} from 'wix-ui-tpa'
import {ErrorSmall} from '@wix/wix-ui-icons-common/on-stage'

import {classes, st} from './styles.st.css'

interface Props {
  text: string
  className?: string
}

export const FormError: FC<Props> = ({text, className}) => (
  <div className={st(classes.error, className)}>
    <div className={classes.errorIcon}>
      <ErrorSmall />
    </div>
    <Text>{text}</Text>
  </div>
)
