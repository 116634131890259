import {StylesParamsType} from '../../types/createStylesParams'
import {
  buttonDefaultStylesParams,
  buttonHoverStylesParams,
  ButtonDefaultStylesParams,
  ButtonHoverStylesParams,
} from '../../components-shared/Settings/Button/stylesParams'
import {
  formBoxStylesParams,
  formLineStylesParams,
  FormBoxStylesParams,
  FormLineStylesParams,
} from '../../components-shared/Settings/FormDesign/stylesParams'

import {generalStyleParams, GeneralStylesParams} from './Settings/Tabs/Design/General/stylesParams'
import {
  DetailsSectionStylesParams,
  detailsSectionStylesParams,
} from './Settings/Tabs/Design/Details/stylesParams'
import {mobileStyleParams, MobileStylesParams} from './Settings.mobile/stylesParams'

type DesktopStylesParams = GeneralStylesParams &
  DetailsSectionStylesParams &
  FormBoxStylesParams &
  FormLineStylesParams &
  ButtonDefaultStylesParams &
  ButtonHoverStylesParams

export const desktopStyleParams: StylesParamsType<DesktopStylesParams> = {
  ...generalStyleParams,
  ...detailsSectionStylesParams,
  ...formBoxStylesParams,
  ...formLineStylesParams,
  ...buttonDefaultStylesParams,
  ...buttonHoverStylesParams,
}

type StylesParams = DesktopStylesParams & MobileStylesParams

export const allStyleParams: StylesParamsType<StylesParams> = {
  ...desktopStyleParams,
  ...mobileStyleParams,
}

export default allStyleParams
