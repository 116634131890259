import React, {FC} from 'react'
import {useEnvironment, WidgetProps} from '@wix/yoshi-flow-editor'
import {Text} from 'wix-ui-tpa'
import {useSettings, useStyles} from '@wix/tpa-settings/react'

import {CountdownTimer} from '../components/CountdownTimer'
import {ReservationDetailsSection} from '../components/ReservationDetailsSection'
import {Form} from '../components/Form'
import {withReservationDetailsStorageProvider} from '../storage'
import {ErrorModal} from '../components/ErrorModal'
import {allStyleParams as stylesParams} from '../stylesParams'
import {settingsParams} from '../settingsParams'
import {RtlProvider} from '../../../components-shared/RtlProvider'

import {useHooks} from './useHooks'
import {classes, st} from './Widget.st.css'
import {reservationDetailsDataHooks} from './constants'

const Widget: FC<WidgetProps> = () => {
  const {reservation, isManualApproval} = useHooks()
  const {isMobile} = useEnvironment()
  const styles = useStyles()
  const settings = useSettings()

  const titleTag =
    styles.get(stylesParams.titleFont).htmlTag ??
    styles.getDefaultValue(stylesParams.titleFont).htmlTag

  const titleText = settings.get(
    isManualApproval ? settingsParams.titleRequestText : settingsParams.titleText,
  )

  if (!reservation?.id && !isManualApproval) {
    return <ErrorModal />
  }

  const title = (
    <Text className={classes.title} tagName={titleTag} aria-flowto="tr-timer">
      {titleText}
    </Text>
  )

  return (
    <RtlProvider>
      <div
        className={st(classes.root, {
          mobile: isMobile,
        })}
        data-hook={reservationDetailsDataHooks.root()}
      >
        {isMobile ? title : null}
        <div className={classes.formWrap}>
          {isMobile ? null : title}
          <Form />
        </div>

        <div className={classes.detailsWrap}>
          {reservation ? <CountdownTimer /> : null}

          <ReservationDetailsSection />
          <ErrorModal />
        </div>
      </div>
    </RtlProvider>
  )
}

export default withReservationDetailsStorageProvider(Widget)
