import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import React from 'react'
import {Accordion, Text, TextButton} from 'wix-ui-tpa'
import {useStyles} from '@wix/tpa-settings/react'

import {getLongDate} from '../../../../utils/dateString'
import stylesParams from '../../stylesParams'

import {useHooks} from './useHooks'
import {classes, st} from './ReservationDetailsSection.st.css'
import {reservationDetailsSectionDataHooks} from './constants'

interface Props {}

export const ReservationDetailsSection: React.FC<Props> = () => {
  const {
    startDate,
    partySize,
    handleChangeReservationClick,
    regionalSettings,
    locationName,
    isManualApproval,
  } = useHooks()
  const {isMobile} = useEnvironment()
  const {t} = useTranslation()
  const styles = useStyles()
  const formattedDate = getLongDate(startDate, regionalSettings)

  const titleTag =
    styles.get(stylesParams.detailsSectionSubtitleFont).htmlTag ??
    styles.getDefaultValue(stylesParams.detailsSectionSubtitleFont).htmlTag

  const content = (
    <section className={classes.section}>
      {isMobile ? null : (
        <Text className={classes.title} tagName={titleTag}>
          {t(
            isManualApproval
              ? 'uou-reservations.reservation-details.request-details.title'
              : 'uou-reservations.reservation-details.details.title',
          )}
        </Text>
      )}

      <Text className={st(classes.text, classes.dateText)} tagName="time">
        {formattedDate}
      </Text>
      <Text className={classes.text} tagName="p">
        {t('uou-reservations.shared.guests', {
          number: partySize ?? 0,
        })}
      </Text>
      <Text className={classes.text} tagName="p">
        {locationName}
      </Text>

      <TextButton
        className={classes.button}
        onClick={handleChangeReservationClick}
        data-hook={reservationDetailsSectionDataHooks.changeReservation()}
      >
        {t('uou-reservations.reservation-details.details.change-cta')}
      </TextButton>
    </section>
  )

  return (
    <div
      id="tr-details"
      aria-flowto="tr-form"
      className={st(classes.root, {
        mobile: isMobile,
      })}
    >
      {isMobile ? (
        <Accordion className={classes.accordion}>
          <Accordion.Item
            id="id"
            title={t(
              isManualApproval
                ? 'uou-reservations.reservation-details.request-details.title'
                : 'uou-reservations.reservation-details.details.title',
            ).replace(':', '')}
          >
            {content}
          </Accordion.Item>
        </Accordion>
      ) : (
        content
      )}
    </div>
  )
}
