import {ChangeEvent, useEffect, useMemo, useState} from 'react'
import {parsePhoneNumber} from 'react-phone-number-input'
import {useEnvironment} from '@wix/yoshi-flow-editor'

import {useReservationDetailsStorage} from '../../storage'
import {useBi} from '../../../../utils/useBi'

import {FormUI, getFormInitialState} from './constants'
import {validateForm} from './validation'

export const useHooks = () => {
  const {
    reservation,
    isManualApproval,
    reservationDataQueryParams,
    submitReservationStatus,
    changeReservation,
    reserveReservation,
    createReservation,
    reservationLocations,
    deleteReservation,
  } = useReservationDetailsStorage()
  const logger = useBi()

  const {isPreview} = useEnvironment()

  const currentReservationLocation = reservationLocations.find(
    ({id}) =>
      id ===
      (isManualApproval
        ? reservationDataQueryParams?.reservationLocationId
        : reservation?.details?.reservationLocationId),
  )

  const formInitialState = useMemo(() => getFormInitialState(currentReservationLocation), []) // eslint-disable-line react-hooks/exhaustive-deps

  const [form, setForm] = useState<FormUI>(formInitialState)

  const [marketingCheckbox, setMarketingCheckbox] = useState<boolean>(
    !!currentReservationLocation?.configuration?.reservationForm?.emailMarketingCheckbox
      ?.checkedByDefault,
  )

  const handleMarketingCheckboxChange = () => {
    setMarketingCheckbox(!marketingCheckbox)
  }

  const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event.target.name as keyof FormUI]: {
        valid: true,
        isRequired: form[event.target.name].isRequired,
        value: event.target.value,
      },
    })
  }

  const handleCustomFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      customFields: {
        ...form.customFields,
        [event.target.name as keyof FormUI['customFields']]: {
          valid: true,
          isRequired: form.customFields[event.target.name].isRequired,
          value: event.target.value,
        },
      },
    })
  }

  const handlePhoneNumberChange = (value: string) => {
    setForm({
      ...form,
      phoneNumber: {
        valid: true,
        isRequired: true,
        value,
      },
    })
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault()

    const {state, valid, validFieldsNumber, invalidFieldsNumber} = validateForm(form)

    setForm(state)

    logger.clickOnCompleteReservation(
      {
        locationId: currentReservationLocation?.location?.id,
        reservationLocationId: currentReservationLocation?.id,
        mailCheckbox: marketingCheckbox,
        numInvaldFields: invalidFieldsNumber,
        numValidFields: validFieldsNumber,
        areaCode: getPhoneCodeFromNumber(form.phoneNumber.value),
        isPreview,
      },
      reservation,
    )

    if (!valid) {
      return
    }

    const reserveeParams = {
      firstName: form.firstName.value,
      lastName: form.lastName.value || undefined,
      email: form.email.value || undefined,
      phone: form.phoneNumber.value,
      marketingConsent: marketingCheckbox,
      customFields: Object.keys(form.customFields).reduce(
        (acc, id) => ({...acc, [id]: form.customFields[id].value}),
        {},
      ),
    }

    if (isManualApproval) {
      const reservationDetails = {
        partySize: Number(reservationDataQueryParams!.partySize),
        startDate: new Date(reservationDataQueryParams!.startDate!),
        reservationLocationId: reservationDataQueryParams!.reservationLocationId,
      }
      await createReservation(reserveeParams, reservationDetails)
    } else {
      if (!reservation) {
        return
      }

      await reserveReservation(reservation, reserveeParams)

      logger.reservationCreated({
        isPreview,
        reservation,
        reservationLocation: currentReservationLocation,
        reserveeParams,
      })
    }
  }

  useEffect(() => {
    if (isManualApproval) {
      return
    }

    const handlePopStateChange = () => {
      if (reservation?.id && reservation?.revision) {
        deleteReservation({reservationId: reservation.id, revision: reservation.revision})
      }
    }

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      return (event.returnValue = '')
    }

    window.addEventListener('popstate', handlePopStateChange)
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('popstate', handlePopStateChange)
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    form,
    currentReservationLocation,
    submitReservationStatus,
    marketingCheckbox,
    isManualApproval,
    changeReservation,
    handleMarketingCheckboxChange,
    handlePhoneNumberChange,
    handleTextFieldChange,
    handleCustomFieldChange,
    handleFormSubmit,
  }
}

export const getPhoneCodeFromNumber = (phoneNumber: string): string => {
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber)
  return parsedPhoneNumber ? `+${parsedPhoneNumber?.countryCallingCode}` : ''
}
